<template>
  <div>
      <h1 class="display-3 d-flex align-items-center font-weight-light overflow-hidden position-absolute text-white" style="height: 66px; margin: 0 0 0 30px; top: 0; z-index: 1">
      <h6 class="h2 text-white d-inline-block mb-0">
        <i class="ni ni-bullet-list-67"></i> Pelaporan Progress
      </h6>
    </h1>
    
    <base-header class="pb-6">
        <b-row>
          <b-col xl="12" md="12">
            <b-card >
              <h5>Cari Berdasarkan</h5>
              <div class="d-flex justify-content-start mb-4">
                    <div class="col-2" v-if="log_as == 'MD'">
                      <base-input class="m-0">
                          <label><small>Jenis Industri</small></label>
                          <el-select filterable placeholder="Jenis Industri" v-model="userInput.companyType">
                            <el-option value="">Semua</el-option>
                            <el-option
                              v-for="option in provideSelect.companyType"
                              :key="option.company_type_uid"
                              :label="option.company_type_name"
                              :value="option.company_type_uid"
                            ></el-option>
                          </el-select>
                        </base-input>
                    </div>
      
                   
      
                    <div class="col-2" v-if="log_as == 'MD'">
                      <base-input class="m-0">
                          <label><small>Provinsi</small></label>
                          <el-select filterable placeholder="Provinsi" v-model="userInput.province" @change="getCityDepentOnProvince(userInput.province)">
                            <el-option value="">Semua</el-option>
                            <el-option
                              v-for="option in provideSelect.province"
                              :key="option.company_province_id"
                              :label="option.company_province_name"
                              :value="option.company_province_id"
                            ></el-option>
                          </el-select>
                        </base-input>
                    </div>
      
                    <div class="col-2" v-if="log_as == 'MD'">
                      <base-input class="m-0">
                          <label><small>Kabupaten / Kota</small></label>
                          <el-select filterable placeholder="Kabupaten" v-model="userInput.regency">
                            <el-option value="">Semua</el-option>
                            <el-option
                              v-for="option in provideSelect.regency"
                              :key="option.company_regency_id"
                              :label="option.company_regency_name"
                              :value="option.company_regency_id"
                            ></el-option>
                          </el-select>
                        </base-input>
                    </div>

                    <div class="col-2" v-if="log_as == 'MD'">
                      <base-input class="m-0">
                          <label><small>Nama Industri</small></label>
                          <el-select filterable placeholder="Nama Industri" v-model="userInput.companyName">
                            <el-option value="">Semua</el-option>
                            <el-option
                              v-for="option in provideSelect.companyName"
                              :key="option.uid"
                              :label="option.name"
                              :value="option.uid"
                            ></el-option>
                          </el-select>
                        </base-input>
                    </div>
      
                    <!-- <div class="col-2">
                      <base-input class="m-0">
                          <label><small>Tipe Pendaftaran</small></label>
                          <el-select filterable placeholder="Tipe Pendaftaran" v-model="userInput.registrationType">
                            <el-option value="SP">Semua</el-option>
                            <el-option
                              v-for="option in provideSelect.registrationType"
                              :key="option.type"
                              :label="option.type"
                              :value="option.type"
                            ></el-option>
                          </el-select>
                        </base-input>
                    </div> -->
                  </div>
                  <div class="col-2">
                    <base-button size="md" type="primary"> Tampilkan </base-button>
                  </div>
            </b-card>
          </b-col>
        </b-row>
    </base-header>

    <b-container fluid class="mt--6">
      <b-row>
        <b-col xl="12">
          <b-card>
            <template v-slot:header>
            <b-row>
              <b-col lg="6" cols="7">
                <!-- <h6 class="surtitle">Overview</h6>
                <h5 class="h3 mb-0">Total sales</h5> -->
              </b-col>
              <b-col lg="6" cols="7" style="display: flex; justify-content: flex-end">
                  <base-button>Download</base-button>
              </b-col>
            </b-row>
            </template>
            <PelaporanProgressTable :tableData="listData"/>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import API from '../../../api/base_url'
import BaseHeader from "@/components/BaseHeader";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { Select, Option } from "element-ui";
import PelaporanProgressTable from '../../Tables/PelaporanProgress.vue';


export default {
components: {
  BaseHeader,
  flatPicker,
  [Select.name]: Select,
  [Option.name]: Option,
  PelaporanProgressTable,
},
mounted() {
  this.getData(),
  // this.getRegistrationType()
  this.getCompiencePoint()
  this.getCompanyType()
  this.getCompanyName()
  this.getProvince()
  this.log_as =  localStorage.getItem('as')
},
data() {
  return {
    log_as:"",
    isLoading:false,
    listData: [],
    provideSelect: {
      flatPickerConfig: {
        dateFormat: "U",
        allowInput: true,
        altInput: true,
        altFormat: "d-m-Y H:i",
        enableTime: true,
        time_24hr: true,
        defaultHour: 0,
        mode: "range"
      },
      registrationType:[],
      compiencePoint:[],
      companyType:[],
      companyName:[],
      province:[],
      regency:[]
    },
    userInput: {
      tanggal: "",
      registrationType:"SP",
      titikPenaatan:"",
      companyType:"",
      companyName:"",
      province:"",
      regency:""
    }
  };
},
methods: {
    getCityDepentOnProvince(data) {
      let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`
      }
        this.provideSelect.regency = [];
        // API.get(`region/regencies/province/${data}`)
        API.get(`web/data/admin/master/regency` , {headers})
          .then(({ data: content }) => {
            this.provideSelect.regency = content.data;
          })
          .catch((err) => {
            console.log(err);
          });
    },
    getProvince() {
      let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`
      }

      API.get('web/data/admin/master/province', {headers})
        .then(({data:content}) => {
          this.provideSelect.province = content.data
        })
        .catch((err) => {
          console.log(err);
        })
    },
    getCompanyName() {
      let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`
      }

      API.get('web/data/admin/master/company', {headers})
        .then(({data:content}) => {
          this.provideSelect.companyName = content.data
        })
        .catch((err) => {
          console.log(err);
        })
    },
    getCompanyType() {
      let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`
      }

      API.get('web/data/admin/master/company-type', {headers})
        .then(({data:content}) => {
          this.provideSelect.companyType = content.data
        })
        .catch((err) => {
          console.log(err);
        })
    },
    getCompiencePoint() {
      let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`
      }

      API.get('web/data/user/master/compliance-point', {headers})
        .then(({data:content}) => {
          this.provideSelect.compiencePoint = content.data
        })
        .catch((err) => {
          console.log(err);
        })
    },
    getRegistrationType() {
      let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`
      }

      API.get('web/data/user/master/registration-type', {headers})
        .then(({data:content}) => {
          this.provideSelect.registrationType = content.data
        })
        .catch((err) => {
          console.log(err);
        })
    },
    getData() {
        this.isLoading = true
        let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
          /**
           * registration_type
           * compliance_point_id
           * company_type_uid
           * company_uid
           * province_id
           * regency_id
           */
          API.get(`web/early-warning/user/trend/monthly?compliance_point_id&date_start&date_finish` , {headers})
            .then(({data:content}) => {
                this.listData = content.data
                this.isLoading = false
            })
            .catch((err) => {
              console.log(err);
              this.isLoading = false
          })
    },
  },
};
</script>

  